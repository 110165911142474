import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../contexts/Context";
import CheckoutPanel from "./CheckoutPanel";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";
import promo from './customerComponents/Promotion';
import TableInfo from './CheckOutInfo/TableInfo';
import ReserveInfo from "./CheckOutInfo/ReserveInfo";
import PickupInfo from "./CheckOutInfo/PickupInfo";
import DeliveryInfo from "./CheckOutInfo/DeliveryInfo";
import KitchenStatus from "./KitchenStatus";
const ReviewOrderModal = ({ show, setShow }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [cartHistory, setCartHistory] = useState([]);
    const [subtotalHistory, setSubtotalHistory] = useState(0);
    const [subtotalDefaultHistory, setSubtotalDefaultHistory] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [takeAwayId, setTakeAwayId] = useState("");
    const { height, width } = useWindowDimensions();
    const [discount, setDiscount] = useState(null);
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, currencySymbol, paymentData, tax, isTakeAway, token, userId, orderTime, isMenuView, tableLabel, showKitchenStatus } = React.useContext(UserContext);
    const [deliveryType, setDeliveryType] = useState(-1);
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [scheduleTime, setScheduleTime] = useState(0);


    useEffect(() => {
        setDisplay(show ? "flex" : "none");
        if (show && token && userId) {
            let orderId = localStorage.getItem("orderId");
            axios.get(`/v2/api/selfOrder/order?userId=${userId}&orderTime=${orderTime}&orderId=${orderId}`, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    // handle success
                    setDiscount(response.data.discount);
                    setCartHistory(response.data.orderData);
                    setSubtotalDefaultHistory(promo.subTotalDefaultAmount(response.data.orderData));
                    setSubtotalHistory(promo.subTotalAmount(response.data.orderData));
                    setTakeAwayId(response.data.takeAwayId);
                    setDeliveryType(response.data.deliveryType);
                    setPhone(response.data.phone);
                    setCustomerName(response.data.customerName);
                    setScheduleTime(response.data.scheduleTime);
                    setAddress(response.data.address);
                    setDeliveryFee(response.data.deliveryFee);

                })
                .catch(function (response) {
                    console.log(response);
                })
        }

    }, [show, token, userId]);


    useEffect(() => {
        setSubtotalHistory(promo.subTotalAmount(cartHistory, discount));
        setSubtotalDefaultHistory(promo.subTotalDefaultAmount(cartHistory))
    }, [cartHistory]);

    let serviceAmt = ((!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0));
    {/* {!isTakeAway && !isMenuView &&
                        <TableDisplay themeContext={themeContext}>
                            {languageContext[lanIndex].table}: {tableLabel}
                        </TableDisplay>
                    } */}
    return (
        <React.Fragment>
            <ModalContainer display={displayShow} themeContext={themeContext}>
                <TitleWrapper>
                    <ReviewTitle themeContext={themeContext}>
                        {languageContext[lanIndex].reviewOrder} {takeAwayId ? " : " + takeAwayId : (!isTakeAway && !isMenuView ? " : " + tableLabel : "")}
                    </ReviewTitle>
                    <IconButton onClick={() => setShow(false)}>
                        <IoCloseCircle size={24} />
                    </IconButton>
                </TitleWrapper>
                <ContentWrapper>
                    {!isTakeAway && deliveryType == 4 &&
                        <ReserveInfo />
                    }
                    {!isTakeAway && deliveryType !== 4 &&
                        <TableInfo />
                    }
                    {isTakeAway && deliveryType == 0 &&
                        <PickupInfo setShow={() => { }}
                            customerName={customerName}
                            phone={phone}
                            scheduleTime={scheduleTime}
                        />
                    }
                    {isTakeAway && deliveryType == 2 &&
                        <DeliveryInfo setShow={() => { }}
                            customerName={customerName}
                            phone={phone}
                            scheduleTime={scheduleTime}
                            address={address}
                        />
                    }
                    {showKitchenStatus && <KitchenStatus />}
                    {cartHistory.map((item, index) => {
                        return (
                            <CheckoutPanel
                                key={item.codeId + index + item.title}
                                cart={[]}
                                handleDelete={() => { }}
                                index={index}
                                item={item}
                                height={height}
                                width={width}
                                lanIndex={lanIndex}
                                deleteActive={false}
                                currencySymbol={currencySymbol}
                            />
                        );
                    })}

                </ContentWrapper>
                <SubTotalWrapper>
                    <SubtotalAndTaxContainer
                        themeContext={themeContext}
                        width={Math.min(width, 550) - 40}
                    >
                        <LeftColumn>
                            {languageContext[lanIndex].subtotal}
                        </LeftColumn>
                        <RightColumn>
                            {currencySymbol}&nbsp;{subtotalDefaultHistory.toFixed(2)}
                        </RightColumn>
                    </SubtotalAndTaxContainer>
                    {subtotalDefaultHistory != subtotalHistory &&
                        <SubtotalAndTaxContainer
                            themeContext={themeContext}
                            width={Math.min(width, 550) - 40}
                        >
                            <LeftColumn>
                                {languageContext[lanIndex].discount}
                            </LeftColumn>
                            <RightColumn>
                                {currencySymbol}&nbsp;{(subtotalHistory - subtotalDefaultHistory).toFixed(2)}
                            </RightColumn>
                        </SubtotalAndTaxContainer>

                    }
                    {(paymentData.serviceCharge > 0 || tax.tax > 0) &&
                        <SubtotalAndTaxContainer
                            themeContext={themeContext}
                            width={Math.min(width, 550) - 40}
                        >
                            <LeftColumn>
                                {tax.tax != 0 ? languageContext[lanIndex].tax : ""} {(isTakeAway && paymentData.shouldExclude) || paymentData.serviceCharge == 0 ? "" : `& ${languageContext[lanIndex].serviceCharge}`}
                            </LeftColumn>
                            <RightColumn>
                                {currencySymbol}&nbsp;{(

                                    subtotalHistory *
                                    (1 + serviceAmt) *
                                    (1 + tax.tax) -
                                    subtotalHistory
                                ).toFixed(2)}
                            </RightColumn>
                        </SubtotalAndTaxContainer>
                    }
                    {deliveryFee > 0 &&
                        <SubtotalAndTaxContainer
                            themeContext={themeContext}
                            width={Math.min(width, 550) - 40}
                        >
                            <LeftColumn>
                                {languageContext[lanIndex].deliveryFee}
                            </LeftColumn>
                            <RightColumn>
                                {currencySymbol}&nbsp;{(deliveryFee).toFixed(2)}
                            </RightColumn>
                        </SubtotalAndTaxContainer>
                    }
                    <TotalContainer themeContext={themeContext} width={Math.min(width, 550) - 40}>
                        <LeftColumn>
                            {languageContext[lanIndex].total}
                        </LeftColumn>
                        <RightColumn>
                            {currencySymbol}&nbsp;{
                                //subtotalHistory +
                                (
                                    subtotalHistory *
                                    (1 + serviceAmt) *
                                    (1 + tax.tax)
                                    + deliveryFee
                                ).toFixed(2)
                            }
                        </RightColumn>
                    </TotalContainer>
                    {((!isTakeAway && !paymentData.shouldExclude) || ((paymentData.serviceCharge > 0 && !paymentData.shouldExcludeDI) || tax.tax > 0)) &&
                        <DisclaimerContainer themeContext={themeContext} width={Math.min(width, 550) - 40}>
                            *{languageContext[lanIndex].serviceChargeReminder}{paymentData.serviceCharge == 0 ? "" : "& " + languageContext[lanIndex].serviceCharge}
                        </DisclaimerContainer>
                    }
                </SubTotalWrapper>
            </ModalContainer>
        </React.Fragment>
    );
}

export default ReviewOrderModal;
const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;

  z-index: 99;
`;
const TitleWrapper = styled.div`
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e3e3e3;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.5);
`
const ContentWrapper = styled.div`
    flex-grow:1;
    overflow: auto;
    margin-top: 16px;
`;
const ReviewTitle = styled.div`
    color:${props => props.themeContext.fontPrimary};
    font-size: 18px;
    font-weight: 500;

`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;
    background-color: ${(props) => props.themeContext.backgroundColor};
    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 100; 
    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;
const SubTotalWrapper = styled.div`
  display: grid;
  place-content: center;
  margin-bottom: 32px;
  padding-top: 8px;
  border-top: 1px solid rgb(227, 227, 227);
`;
const SubtotalAndTaxContainer = styled.div`
  display: flex;
  color: ${(props) => props.themeContext.fontPrimary};
  font-family: ${(props) => props.themeContext.fontFamily};
  width: ${(props) => props.width}px;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;
`;
const LeftColumn = styled.div`
  flex-grow: 1;
`;
const RightColumn = styled.div`
  text-align: right;
`;

const TotalContainer = styled.div`
  display: flex;
  /*color: ${(props) => props.themeContext.fontPrice};*/
  font-family: ${(props) => props.themeContext.fontFamily};
  
  width: ${(props) => props.width}px;
  font-weight: 700;
  font-size: 18px;
`;

const DisclaimerContainer = styled.div`
  text-align: right;
  color: ${(props) => props.themeContext.fontPrimary};
  font-family: ${(props) => props.themeContext.fontFamily};
  width: ${(props) => props.width}px;

  font-weight: 500;
  font-size: 12px;
`;
