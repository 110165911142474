/* eslint-disable no-console */
import React, { useRef, useState, useEffect } from "react";
import MenuSideBar from "../newcomponents/MenuSideBar";
import MenuContainer from "../newcomponents/MenuContainer";
import Modal from "../newcomponents/optioncomponents/Modal";
import ModalItem from "../newcomponents/optioncomponents/ModalItem";
import { navigate } from "@reach/router";
import moment from 'moment';
import styled from "@emotion/styled";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { IoCartOutline, IoChevronForwardOutline } from "react-icons/io5";
import { UserContext, ActionContext, ThemeContext, LanguageContext } from "../contexts/Context";
import axios from "axios";
import MenuTopBar from '../newcomponents/MenuTopBar';
import promo from '../newcomponents/customerComponents/Promotion';
import Header from "../newcomponents/Header";

// import menulist from "./Menulist";
// import checkoutrec from "./checkOutRec";
// import { customer } from './customer';
// import containerItems from './containerItems';
// let menuList = menulist;
// let checkOutRec = checkoutrec;
// let isForCheckout = false;

const Menu = ({ category, codeId }) => {
  //Context
  const { cart, otherUrls, isTakeAway, isMenuView, title, menus, theme, lanIndex, disSubCatAtSide, searchTxt, isSearch, isSearching,
    allowTAafterWH, startTime, endTime, token, addItemDirect, unlimitedScroll, isScrolling, hasCategoryScreen, haveToLogin, hasMember,
    catPos, itemLayout, isLanReverse, pm } = React.useContext(UserContext);
  let { menuList, checkOutRec, isForCheckout, customer, containerItems } = React.useContext(UserContext);

  const { addToCart, removeFromCart, setIsSearch, setSearchTxt, setSearching } = React.useContext(ActionContext);
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  //Hook
  const { height, width } = useWindowDimensions();
  const [isLoad, setLoad] = useState(false);
  //State
  const [lastPos, setLastPos] = useState(0);
  const [show, setShow] = useState(false);
  const [recShow, setRecShow] = useState(false);
  const [error, setError] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isSub, setIsSub] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [mnList, setMnList] = useState([]);
  const [selectedRecommended, setSelectedRecommended] = useState(null);
  const [isCheckout, setIsCheckout] = useState(false);
  const [catList, setCatList] = useState([]);
  const [subCatObj, setSubCatObj] = useState({});
  const [subCat, setSubCat] = useState([]);
  const [promos, setPromos] = useState({});

  //Ref to hold selection
  const menuSelectionFormRef = useRef(null);

  useEffect(() => {
    let order = JSON.parse(localStorage.getItem("order"));
    if (order) {
      addToCart(order);
    }
  }, [addToCart]);

  //filter menu
  useEffect(() => {
    if (hasMember === true && haveToLogin === true && (customer == "" || customer == null)) {
      window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
    }
    let checkinTime = localStorage.getItem("checkIn");
    if (checkinTime) {
      let diff = (new Date().getTime() - checkinTime) / 1000 / 60 / 60;

      if (diff > 1) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }


    if (isTakeAway && !allowTAafterWH) {
      let stime = moment(startTime);
      let etime = moment(endTime);
      let st = moment().set("hour", stime.get("hour")).set("minute", stime.get("minute"));
      let et = moment().set("hour", etime.get("hour")).set("minute", etime.get("minute"));
      if (stime.get("hour") < etime.get('hour')) {
        et = et.add(24, "hour");
      }
      if (moment().isBefore(st) || moment().isAfter(et)) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }
  }, [isTakeAway]);

  useEffect(() => {
    if (isSearch) {
      setSelectedCategory(null);
      setSelectedMenu(null);
      setIsSearch(false);
    }
  }, [isSearch])

  const isSelected = (subCat) => {
    for (let cat of subCat) {
      if (selectedCategory != null && cat.category == selectedCategory.category) return true;
    }
    return false;
  };



  useEffect(() => {

    if (menus.length == 0) return;
    if (title && menus && menus.length > 1) {
      menuList = menuList.filter((mn) => {
        return mn.title == title;
      })
    }
    let mnList = JSON.parse(JSON.stringify(menuList));
    if (searchTxt) {
      mnList = mnList.filter(mn => {
        let isMatch = false;
        if (mn.hasLimitTime) {
          let isWithin;
          let startTime = moment(moment(mn.startTime).format("HH:mm"), 'HH:mm');
          let endTime = moment(moment(mn.endTime).format("HH:mm"), 'HH:mm');
          let now = moment();
          if (startTime.valueOf() <= endTime.valueOf()) {
            isWithin = (now.isAfter(startTime) && now.isBefore(endTime));
          } else if (now.valueOf() < startTime.valueOf()) {
            startTime = startTime.subtract(1, "day");
          } else {
            endTime = endTime.add(1, "day");
          }

          isWithin = (now.isAfter(startTime) && now.isBefore(endTime));
          if (!(mn.isInclude == 0 || mn.isInclude == "0" ? isWithin : !isWithin)) {
            return false;
          }
        }

        if (mn.category.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())) {
          isMatch = true;
        } else {
          mn.type = mn.type.filter((it) => {
            if (!it.isCategory && it.title && it.title.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())) {
              isMatch = true;
              it.size = 0;
              return true;
            } else if (it.isCategory) {
              it.type = it.type.filter(i => {
                if (i.title && i.title.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())) {
                  isMatch = true;
                  it.size = 0;
                  return true;
                } else {
                  return false;
                }
              });
              return it.type.length > 0;
            } else {
              return false;
            }
          });
        }

        return isMatch;
      });

    }

    if (selectedCategory != null && selectedMenu == null) {
      //need to update the selected Category
      for (let cat of mnList) {
        if (cat.isCategory) {
          for (let subCat of cat.type) {
            if (subCat.category == selectedCategory.category) {
              setSelectedCategory(subCat);
              break;
            }
          }
        } else if (cat.category == selectedCategory.category) {
          setSelectedCategory(cat);
          break;
        }
      }
    }
    setMnList(mnList);
  }, [searchTxt, title, menus]);

  useEffect(() => {
    if (isLoad) return;
    if (menuList.length == 0) return;
    if (menus.length == 0) return;
    if (title && menus && menus.length > 1) {
      menuList = menuList.filter((mn) => {
        return mn.title == title;
      })
    }
    let mnList = JSON.parse(JSON.stringify(menuList));

    if (selectedCategory != null && selectedMenu == null) {
      //need to update the selected Category
      for (let cat of mnList) {
        if (cat.isCategory) {
          for (let subCat of cat.type) {
            if (subCat.category == selectedCategory.category) {
              setSelectedCategory(subCat);
              break;
            }
          }
        } else if (cat.category == selectedCategory.category) {
          setSelectedCategory(cat);
          break;
        }
      }
    }
    setLoad(true);
    setMnList(mnList);
  }, [isLoad, menuList, title, menus]);

  useEffect(() => {
    let catList = [], subCatObj = {}, subCat = [];
    mnList.forEach((mn) => {
      subCat = [];
      catList.push(mn);
      if (mn.type) {
        mn.type.forEach(ci => {
          if (ci.isCategory) {
            ci.isSub = true;
            subCat.push(ci);
            if (!subCatObj[mn.category]) subCatObj[mn.category] = [];
            subCatObj[mn.category].push(ci);
          }
        });
      }
      if (disSubCatAtSide && selectedCategory != null &&
        (selectedCategory.category == mn.category || isSelected(subCat)) &&
        catPos == 0) {
        catList = catList.concat(subCat);
      }
    });
    setCatList(catList);
    setSubCatObj(subCatObj);
    setSubCat(subCat);
  }, [mnList, selectedCategory])

  useEffect(() => {
    if (selectedCategory == null) return;

    if (subCatObj[selectedCategory.category]) {
      setIsSub(true);
      return;
    };
    for (let key in subCatObj) {
      for (let sc of subCatObj[key]) {
        if (sc.category == selectedCategory.category) {
          setIsSub(true);
          return;
        }
      }
    }
    setIsSub(false);
  }, [selectedCategory]);

  const onBackChange = (e) => {

    setSelectedMenu(null);
    if ((!category || category == null)) {
      window.history.pushState(null, null, "/menu");
    } else if (window.location && window.location.pathname.includes("/menu/menu")) {
      setSelectedMenu(null);
    } else {
      window.history.pushState(null, null, "/menu");
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', onBackChange, false);
    return () => {
      window.removeEventListener('popstate', onBackChange);
    }
  }, []);

  useEffect(() => {
    if (!token || !title) return;
    //check if qrcode is buffet and is expired or not
    axios.post("https://selforder.ireachfnb.com/v2/api/selfOrder/valid", { title: title }, { headers: { Authorization: "Bearer " + token } })
      // axios.post("https://selforder2.chachingfnb.com/v2/api/selfOrder/valid", { title: title }, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        if (res.status == 400) {
          window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
        }
      })
      .catch((e) => {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      });
  }, [token, title]);

  useEffect(() => {
    if (unlimitedScroll) return;

    if (codeId != null && codeId) {
      menuList.forEach(mn => {
        mn.type.forEach(m => {
          if (!m.isCategory && m.codeId == codeId) {
            setSelectedMenu(m);
            setSelectedCategory(mn);
          } else if (m.isCategory) {
            m.type.forEach(c => {
              if (c.codeId == codeId) {
                setSelectedMenu(c);
                setSelectedCategory(m);
              }
            })
          }
        })
      });
    } else if (category != null && category) {
      menuList.forEach(mn => {
        let mCat = mn.category;
        mCat = mCat.replaceAll("/", "").replaceAll("%", "");

        if (mCat == category) {
          setSelectedMenu(null);
          setSelectedCategory(mn);
        }
        mn.type.forEach((m) => {
          mCat = m.category;
          if (mCat) {
            mCat = mCat.replaceAll("/", "").replaceAll("%", "");
            if (m.isCategory && mCat == category) {
              setSelectedMenu(null);
              setSelectedCategory(m);
            }
          }
        })
      })
    } else if (codeId == null && category == null) {
      setSelectedMenu(null);
    }
  }, [codeId, category]);

  useEffect(() => {
    if (selectedMenu) {
      if (selectedMenu.recommendedArray.length > 0) {
        setSelectedRecommended(JSON.parse(JSON.stringify(selectedMenu.recommendedArray)));
      }
      //no need to go to item detail, add to cart straightaway
      if (addItemDirect && (!selectedMenu.optionArray || selectedMenu.optionArray.length == 0)) {

        menuSelectionFormRef.current = JSON.parse(JSON.stringify(selectedMenu));

        addNewItemToCart(selectedMenu);
        setSelectedMenu(null);
      }
    }
  }, [selectedMenu]);



  //Method
  const addRecItemToCart = (items) => {
    let hasCkItem = false;
    items.forEach((it) => {
      if (it.active) {
        hasCkItem = true;
      }
    });
    if (!hasCkItem) {
      setRecShow(false);
      return;
    }
    let push = true;
    let combine = false;
    let newCart = JSON.parse(JSON.stringify(cart));

    const pushOrCombine = (push, item, newItem) => {
      if (push === true) {
        newCart.push(newItem);
      } else {
        item.qty = item.qty + newItem.qty;
        item.remark = item.remark.concat(newItem.remark);
      }
      localStorage.setItem("order", JSON.stringify(newCart));
    };

    items.forEach((it, index) => {
      if (it.active) {
        let newItem = JSON.parse(JSON.stringify(it));
        newItem.recommendId = "MREC";
        newItem.remark = "";
        let cartIndex;
        let optionArrayIndex;
        let optionsIndex;

        let metConflict = false;

        if (newCart.length > 0) {
          for (cartIndex = 0; cartIndex < newCart.length; cartIndex++) {
            let item = newCart[cartIndex];

            if (item.title === newItem.title) {
              for (
                optionArrayIndex = 0;
                optionArrayIndex < item.optionArray.length;
                optionArrayIndex++
              ) {
                let optionArray = item.optionArray[optionArrayIndex];
                for (
                  optionsIndex = 0;
                  optionsIndex < optionArray.options.length;
                  optionsIndex++
                ) {
                  let optionsSelection = optionArray.options[optionsIndex];
                  if (
                    optionsSelection.active !==
                    newItem.optionArray[optionArrayIndex].options[optionsIndex].active
                  ) {

                    metConflict = true;
                    break;
                  }
                }
                if (newItem.remark != item.remark) {
                  console.log("same title, different remark");
                  pushOrCombine(true, "", newItem);
                  return;
                } else if (metConflict === true) {
                  metConflict = false;
                  if (cartIndex === newCart.length - 1) {
                    console.log("same item with conflict all the way to final item");
                    pushOrCombine(push, "", newItem);
                    return; //return;
                  }
                  break;
                } else if (metConflict === false) {
                  if (optionArrayIndex === item.optionArray.length - 1) {
                    console.log("same title, but with no conflict");
                    pushOrCombine(combine, item, newItem);
                    return; //return;
                  }
                }
              }

              if (newItem.remark != item.remark) {
                console.log("same title, different remark");
                pushOrCombine(true, "", newItem);
                return;
              } else if (item.optionArray.length === 0) {
                console.log("same title, but no options so no conflict");
                pushOrCombine(combine, item, newItem);
                return; //return;
              }
            }
            if (cartIndex === newCart.length - 1) {
              console.log("none of same item at all");
              pushOrCombine(push, "", newItem);
              return; //return;
            }
          }
        } else {
          console.log("cart is completely empty");
          pushOrCombine(push, "", newItem);
          return; //return;
        }
      }
    });

    //remove item that is empty
    let i = 0;
    while (i < newCart.length) {
      if (newCart[i].qty == 0) {
        newCart.splice(i);
      } else {
        i++;
      }
    }

    //apply promotion

    if (pm && pm.length > 0) {
      newCart = promo.clearPromotion(newCart);
      newCart = promo.applyPromotion(pm, newCart);
    }
    if (isTakeAway) {
      newCart = promo.removeContainer(newCart);
      newCart = promo.applyContainer(newCart, containerItems);
    }
    localStorage.setItem("order", JSON.stringify(newCart));

    addToCart(newCart);
    setSelectedRecommended(null);
    setRecShow(false);
  }


  const isItemMatch = (item, newItem) => {
    if (item.codeId === newItem.codeId) {
      if (newItem.remark != item.remark) {
        return { isMatch: false, item };
      } else if (item.active != newItem.active) {
        return { isMatch: false, item };
      } else if (!item.optionArray || item.optionArray.length === 0) {
        return { isMatch: true, item };
      }
      let isMatch = true;
      for (let i = 0; i < item.optionArray.length; i++) {
        let optionArray = item.optionArray[i];

        for (let j = 0; j < optionArray.options.length; j++) {
          let optionsSelection = optionArray.options[j];
          if (optionsSelection.active !== newItem.optionArray[i].options[j].active) {

            isMatch = false;
            break;
          }
          if ((optionsSelection.qty && !newItem.optionArray[i].options[j].qty) ||
            (!optionsSelection.qty && newItem.optionArray[i].options[j].qty)
          ) {
            isMatch = false;
            break;
          }
          if (optionsSelection.qty && newItem.optionArray[i].options[j].qty &&
            optionsSelection.qty != newItem.optionArray[i].options[j].qty
          ) {
            isMatch = false;
            break;
          }
        }
        if (!isMatch) break;
      }
      if (!isMatch) return { isMatch: false, item };
      if (isMatch) return { isMatch: true, item };
    }
    return { isMatch: false, item: {} };

  };

  const addNewItemToCart = (newItemSelection, isRecommended, mods) => {
    let push = true;
    let combine = false;
    let newItem;

    if (checkIfSelected(isRecommended, mods)) {
      //return back to home
      if (selectedCategory != null) {
        let cat = selectedCategory.category;
        if (cat != null) {
          cat = cat.replaceAll("/", "").replaceAll("%", "");
          if (!unlimitedScroll)
            navigate('/menu/menu/' + cat);
          else
            navigate('/menu');
        }
      }
      if (isRecommended) {
        newItem = JSON.parse(JSON.stringify(newItemSelection));
        newItem.qty = mods.qty;
        newItem.remark = mods.remark;
      } else {
        newItem = JSON.parse(JSON.stringify(selectedMenu));
        newItem.optionArray = newItemSelection.optionArray;

        //update quantity and remark
        newItem.qty = newItemSelection.qty;
        newItem.remark = newItemSelection.remark ? newItemSelection.remark : "";
      }

      let newCart = JSON.parse(JSON.stringify(cart));

      const pushOrCombine = (push, item) => {
        if (push === true) {
          newCart.push(newItem);
        } else {
          item.qty = item.qty + newItem.qty;
          item.remark = item.remark.concat(newItem.remark);
        }


        if (pm && pm.length > 0) {
          newCart = promo.clearPromotion(newCart);
          newCart = promo.applyPromotion(pm, newCart);
        }
        if (isTakeAway) {
          newCart = promo.removeContainer(newCart);
          newCart = promo.applyContainer(newCart, containerItems);
        }
        localStorage.setItem("order", JSON.stringify(newCart));
        addToCart(newCart);
        if (!isRecommended) {
          if (selectedRecommended && selectedMenu.hasRecommended === true) {
            setRecShow(true);
            setIsCheckout(false);
          }
          setSelectedMenu(null);
        } else {
          setSelectedRecommended(null);
          setRecShow(false);
        }
      };

      if (newItem.isSetMeal === true) {
        for (let item of newCart) {
          let isMatch;
          if (item.codeId === newItem.codeId) {
            if (newItem.remark != item.remark) {
              continue;
            }
            isMatch = true;
            for (let i = 0; i < item.optionArray.length; i++) {
              for (let j = 0; j < item.optionArray[i].options.length; j++) {
                if (!isItemMatch(item.optionArray[i].options[j], newItem.optionArray[i].options[j]).isMatch) {
                  isMatch = false;
                  break;
                }
              }
              if (!isMatch) break;
            }
            if (!isMatch) continue;
            if (isMatch) {
              pushOrCombine(false, item);
              return;
            }
          }


        }
        //isSetMeal different rules
        pushOrCombine(push);
      } else {
        //Combine quantity if order has same options
        let cartIndex;
        let optionArrayIndex;
        let optionsIndex;

        let metConflict = false;

        if (newCart.length > 0) {
          for (cartIndex = 0; cartIndex < newCart.length; cartIndex++) {
            let item = newCart[cartIndex];

            if (item.codeId === newItem.codeId) {
              for (optionArrayIndex = 0; optionArrayIndex < item.optionArray.length; optionArrayIndex++) {
                let optionArray = item.optionArray[optionArrayIndex];
                for (optionsIndex = 0; optionsIndex < optionArray.options.length; optionsIndex++) {
                  let optionsSelection = optionArray.options[optionsIndex];
                  if (optionsSelection.active !== newItem.optionArray[optionArrayIndex].options[optionsIndex].active) {
                    console.log("same title, but with conflicts, set conflict flag");
                    metConflict = true;
                    break;
                  }
                  if ((!optionsSelection.qty && newItem.optionArray[optionArrayIndex].options[optionsIndex].qty) ||
                    (optionsSelection.qty && !newItem.optionArray[optionArrayIndex].options[optionsIndex].qty)) {
                    metConflict = true;
                    break;
                  }
                  if (optionsSelection.qty && newItem.optionArray[optionArrayIndex].options[optionsIndex].qty &&
                    optionsSelection.qty != newItem.optionArray[optionArrayIndex].options[optionsIndex].qty
                  ) {
                    metConflict = true;
                    break;
                  }
                }

                if (newItem.remark != item.remark) {
                  pushOrCombine(true);
                  return;
                } else if (metConflict === true) {
                  console.log(
                    "same title, but met conflict, next item, uncheck conflict flag"
                  );
                  metConflict = false;
                  if (cartIndex === newCart.length - 1) {
                    console.log("same item with conflict all the way to final item");
                    pushOrCombine(push);
                    return; //return;
                  }
                  break;
                } else if (metConflict === false) {
                  if (optionArrayIndex === item.optionArray.length - 1) {
                    console.log("same title, but with no conflict");
                    pushOrCombine(combine, item);
                    return; //return;
                  }
                }
              }
              if (newItem.remark != item.remark) {
                console.log("same title, but different remark");
                pushOrCombine(true);
                return;
              } else if (item.optionArray.length === 0) {
                console.log("same title, but no options so no conflict");
                pushOrCombine(combine, item);
                return; //return;
              }
            }
            if (cartIndex === newCart.length - 1) {
              console.log("none of same item at all");
              pushOrCombine(push);
              return; //return;
            }
          }
        } else {
          console.log("cart is completely empty");
          pushOrCombine(push);
          return; //return;
        }
      }
    }
  };

  //Slightly bugged. //Untested, but logic should be sound.
  const countQty = () => {
    let qty = 0;
    cart.forEach((ct) => {
      qty += ct.qty;
    })
    return qty;
  }

  const checkIfSelected = (isRecommended, mods) => {

    let errorMessages = [];
    let optionArray = isRecommended
      ? menuSelectionFormRef.current.recommendedArray
      : menuSelectionFormRef.current.optionArray;


    let alertSubject = isRecommended ? "Add-on: " : `${languageContext[lanIndex].setMeal}: `;
    let quantityCheck = isRecommended
      ? mods.qty
      : menuSelectionFormRef.current.qty;

    if (isRecommended === true) {
      let element = optionArray[0];
      if (element.optionActive === true && element.optionRequired && element.options.length > 0) {
        let foundActiveOptionArraySelection = element.options.find(
          (option) => option.active === true
        );

        if (!foundActiveOptionArraySelection) {
          errorMessages.push({
            errorElement: element.optionTitle,
            errorMessage: (
              <>
                <b style={{ color: "#CF000D" }}>
                  {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                </b>{" "}
                <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
              </>
            ),
          });
        } else {
          foundActiveOptionArraySelection.optionArray.forEach((element2) => {
            if (element2.optionActive === true && element2.optionRequired) {
              let setMealDepthOptionFound = element2.options.find(
                (option) => option.active === true
              );
              if (!setMealDepthOptionFound) {
                errorMessages.push({
                  errorElement: element2.optionTitle,
                  errorMessage: (
                    <>
                      <b style={{ color: "#CF000D" }}>{(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element2.optionTitle : element2.optionTitle2}</b>
                      {" of "}
                      <b style={{ color: "#CF000D" }}>
                        {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                      </b>{" "}
                      <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                    </>
                  ),
                });
              }
            }
          });
        }
      }
    } else if (menuSelectionFormRef.current.isSetMeal === true) {
      console.log(optionArray);
      optionArray.forEach((element) => {
        if (element.optionActive === true && element.optionRequired && element.options.length > 0) {
          let selected = 0;
          element.options.forEach((option) => { if (option.active) selected += (option.qty ? option.qty : 1); });

          let foundActiveOptionArraySelection = element.options.find((option) => option.active === true);

          if (!foundActiveOptionArraySelection || (element.isMultiple && selected < element.minQty)) {
            errorMessages.push({
              errorElement: element.optionTitle,
              errorMessage: (
                <>
                  <b style={{ color: "#CF000D" }}>
                    {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                  </b>&nbsp;
                  <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                </>
              ),
            });
          } else {
            //nested Option
            foundActiveOptionArraySelection.optionArray &&
              foundActiveOptionArraySelection.optionArray.forEach((element2) => {
                if (element2.optionActive === true && element2.optionRequired) {
                  let selected2 = 0;
                  element2.options.forEach((option) => { if (option.active) selected2 += (option.qty ? option.qty : 1); });
                  let setMealDepthOptionFound = element2.options.find((option) => option.active === true);
                  if ((element2.isMultiple && selected2 < element2.minQty) || !setMealDepthOptionFound) {
                    errorMessages.push({
                      errorElement: element2.optionTitle,
                      errorMessage: (
                        <>
                          <b style={{ color: "#CF000D" }}>
                            {((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element2.optionTitle : element2.optionTitle2)}
                          </b>
                          {" of "}
                          <b style={{ color: "#CF000D" }}>
                            {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                          </b>&nbsp;
                          <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                        </>
                      ),
                    });
                  }
                }
              });
          }
        } else if (element.optionActive === true && element.options.length > 0) {
          //even option is not compulsory, but nested may be compulsory
          let foundActiveOptionArraySelection = element.options.find((option) => option.active === true);
          foundActiveOptionArraySelection && foundActiveOptionArraySelection.optionArray &&
            foundActiveOptionArraySelection.optionArray.forEach((element2) => {
              if (element2.optionActive === true && element2.optionRequired) {
                let selected2 = 0;
                element2.options.forEach((option) => { if (option.active) selected2 += (option.qty ? option.qty : 1); });
                let setMealDepthOptionFound = element2.options.find((option) => option.active === true);
                if ((element2.isMultiple && selected2 < element2.minQty) || !setMealDepthOptionFound) {
                  errorMessages.push({
                    errorElement: element2.optionTitle,
                    errorMessage: (
                      <>
                        <b style={{ color: "#CF000D" }}>
                          {((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element2.optionTitle : element2.optionTitle2)}
                        </b>
                        {" of "}
                        <b style={{ color: "#CF000D" }}>
                          {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                        </b>&nbsp;
                        <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                      </>
                    ),
                  });
                }
              }
            });
        }
      });
    } else {
      optionArray.forEach((element) => {
        if (element.optionActive === true && element.optionRequired && element.options.length > 0) {
          let checkActive = false;
          let selected = 0;

          element.options.forEach((option) => {
            if (option.active === true) {
              checkActive = true;
              selected += (option.qty ? option.qty : 1);
            }
          });

          if ((checkActive === false && !element.isMultiple) || (element.isMultiple && selected < element.minQty)) {
            errorMessages.push({
              errorElement: element.optionTitle,
              errorMessage: (
                <>
                  <b style={{ color: "#CF000D" }}>{(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2}</b>
                  &nbsp;
                  <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                </>
              ),
            });
          }
        }
      });
    }

    if (quantityCheck < 1) {
      errorMessages.push({
        errorElement: "Quantity",
        errorMessage: (
          <>
            <b style={{ color: "#CF000D" }}>Quantity</b> cannot be less than{" "}
            <b>1</b> please re-adjust.
          </>
        ),
      });
    }

    if (errorMessages.length > 0) {
      console.log(errorMessages);
      setError(errorMessages);
      setShow(true);
      return false;
    }
    return true;
  };

  const clickCheckout = () => {
    setIsSearch(false);
    setSearchTxt("");
    setSearching(false);
    if (isForCheckout) {
      setRecShow(true);
      setIsCheckout(true);
      setSelectedRecommended([JSON.parse(JSON.stringify(checkOutRec))]);
    } else {
      navigate('/checkout');
    }
  }

  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  let shouldExpand = false;
  if (selectedMenu == null || (selectedMenu != null && selectedMenu.optionArray.length == 0 && addItemDirect)) {
    shouldExpand = true;
  }
  return (
    <React.Fragment>
      {(selectedRecommended && selectedRecommended != null) &&
        <ModalItem
          show={recShow}
          setShow={setRecShow}
          isCheckout={isCheckout}
          recommendedArray={selectedRecommended}
          setRecommendedArray={setSelectedRecommended}
          menuSelectionFormRef={menuSelectionFormRef}
          addNewItemToCart={addRecItemToCart}

        />
      }
      <Modal show={show} setShow={setShow} error={error} />
      {!isMenuView &&
        <>
          {(selectedMenu && (!addItemDirect || (addItemDirect && selectedMenu.optionArray.length > 0))) ? (
            <AddCartButtonContainer width={width}>
              <AddCartButton
                width={width}
                selectedMenu={selectedMenu}
                themeContext={themeContext}
                onClick={() => {
                  setIsSearch(false);
                  setSearchTxt("");
                  setSearching(false);
                  addNewItemToCart(menuSelectionFormRef.current);
                }}
              >
                {languageContext[lanIndex].addToCart}
              </AddCartButton>
            </AddCartButtonContainer>
          ) : (
            <CartButtonContainer width={width}>
              <CartButton
                width={width}
                selectedMenu={selectedMenu}
                themeContext={themeContext}
                cartLength={cart.length}
                onClick={() => { clickCheckout() }}
              >
                <IoCartOutline color={"white"} size={24} />: <QtyBox>{`${countQty()}`}</QtyBox>
                <CartText>
                  {languageContext[lanIndex].checkout}
                  <IoChevronForwardOutline color={"white"} size={24} />
                </CartText>
              </CartButton>
            </CartButtonContainer>
          )}
        </>
      }
      <Header
        hasBack={selectedMenu == null}
      />
      {!catPos &&
        <MenuSideBar
          height={height}
          itemList={catList}
          otherUrls={otherUrls}
          category={category}
          selectedMenu={selectedMenu}
          unlimitedScroll={unlimitedScroll}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedMenu={setSelectedMenu}
          setLastPos={setLastPos}
        />
      }
      {catPos == 1 &&
        <MenuTopBar
          height={height}
          itemList={catList}
          subCatObj={subCatObj}
          otherUrls={otherUrls}
          category={category}
          width={width}
          selectedMenu={selectedMenu}
          unlimitedScroll={unlimitedScroll}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedMenu={setSelectedMenu}
          setLastPos={setLastPos}
          addItemDirect={addItemDirect}
          shouldExpand={shouldExpand}
        />
      }
      <MenuContainer
        width={width}
        height={height - (catPos == 1 && (shouldExpand) ? 0 + (isSub && !unlimitedScroll ? 35 : 0) : 0)}
        catPos={catPos}
        theme={theme}
        cart={cart}
        menuList={mnList}
        removeFromCart={removeFromCart}
        headerHeight={themeContext.headerHeight + (catPos == 1 && (shouldExpand) ? 50 + (isSub && !unlimitedScroll ? 35 : 0) : 0)}
        sidebarWidth={catPos == 1 ? 0 : themeContext.sidebarWidth}
        menuSelectionFormRef={menuSelectionFormRef}
        selectedMenu={selectedMenu}
        selectedCategory={selectedCategory}
        addItemDirect={addItemDirect}
        unlimitedScroll={unlimitedScroll}
        lanIndex={lanIndex}
        isLanReverse={isLanReverse}
        setSelectedMenu={setSelectedMenu}
        setSelectedCategory={setSelectedCategory}
        isScrolling={isScrolling}
        lastPos={lastPos}
        setLastPos={setLastPos}
        addRecItemToCart={addRecItemToCart}
      />

    </React.Fragment>
  );
};

export default React.memo(Menu);

const QtyBox = styled.div`
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 8px;
  background: rgba(0,0,0,0.6);
  animation: qty .2s;
    animation-timing-function: ease-out;
    @keyframes qty {
        0%  {
        transform: scale(1.2);
        }
        
        100%  {
        transform: scale(1);
        
        }
        
    }
`;
const AddCartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  position: fixed;
  bottom: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: 100%;
  height: 60px;

  /*box-shadow: 0px 0px 14px rgba(0,0,0,0.5);*/
  max-width: 550px;

`;
const CartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  position: fixed;
  bottom: 3px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 5;
  max-width: 550px;
  width: 100%;

`;

const CartText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddCartButton = styled.div`
  display: flex;
 
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 16px;
  background-color: ${(props) => props.themeContext.cartColor2};
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  height: 40px;
  /*right: 20px;*/
  width: 85%;
  max-width: 550px;

  bottom: 10px;
  /*left: 20px;*/
  box-shadow: 0px 0px 13px rgb(0 0 0 / 90%);
  transform: translateX(0px);
  border-radius: 6px;
`;
const CartButton = styled.div`
  display: flex;
  
  bottom: 10px;
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 16px;
  background-color: ${(props) => props.themeContext.cartColor1};
  padding: 0px 16px;
  align-items: center;
  height: 48px;
    /*right: 20px;*/
  width: 85%;
  max-width: 550px;
  bottom: 10px;
  /*left: 20px;*/
  border-radius: 7px;
  transform: translateX(0px);
  box-shadow: 0px 0px 13px rgb(0 0 0 / 90%);
  animation: ${(props) =>
    props.cartLength > 0
      ? "moveBox 2s infinite"
      : "none"
  };
  @keyframes moveBox {
    
    0%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    50%   {
      box-shadow: 1px 1px 10px rgb(0 0 0 / 100%);
      transform: translateX(5px);
    }
    100%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    
  }

`;
