

export const language = [
    {
        minimumConsumptionTip: "The Minimum Consumption is",
        welcome: "Welcome to",
        table: "Table",
        order: "Order",
        checkIn: "Check In",
        noOfPax: "Please enter number of Guest",
        enter: "ENTER",
        paxWarning: "Please input no of Guest.",
        orderWarning: "Please select type of orders",
        checkout: "Submit Order",
        addToCart: "Add To Cart",
        qty: "Qty",
        remark: "Remark",
        bgTxt: "Please select a category to begin ordering.",
        setMeal: "Set Meal",
        required: "is required",
        pleasePick: "please pick one option",
        pleaseSelectItem: "Please Select an Item",
        subtotal: "Subtotal",
        tax: "Tax",
        serviceCharge: "Service Charge",
        submit: "Submit Order",
        serviceChargeReminder: "All prices are subject to prevailing GST",
        total: "Total",
        cart: "Cart",
        reviewOrder: "View Order",
        cartTitle: "CURRENT ORDER",
        reviewTitle: "REVIEW ORDER HISTORY",
        selection: "Selection",
        paymentFail: "Payment Failed",
        paymentFailDesc: "Your transaction has failed.",
        tryAgain: "Please try again.",
        returnCK: "Return to Checkout",
        success: "Success",
        yourOrderNo: "Your Order No",
        orderConfirm: "We have received your purchase request",
        contactShortly: "We will be in touch shortly!",
        returnMain: "Return to Menu",
        cancel: "Cancel",
        cancelDesc: "Click below to return to Checkout",
        name: "Name",
        phone: "Phone",
        ok: "Ok",
        confirmCK: "Submit Order?",
        bookingTime: "Pick Up time",
        deliveryTime: "Delivery Time",
        today: "Today",
        tomorrow: "Tomorrow",
        selectDateWarning: "Please fill in phone and time",
        selectDateWarning1: "Please fill in phone, time and address",
        selectRoomWarning: "Room No, Name and Phone No is require",
        pleaseSelect: "Please choose the way to pick up the meal",
        pleasePayment: "Please select the payment method",
        pleaseSelectOne: "Please select",
        delivery: "Delivery",
        takeIt: "Take it yourself",
        deliveryFee: "Delivery Fee",
        address: "Address",
        remark: "Remark",
        totalAmount: "Total Amount",
        additional: "Additional",
        belowMinSpending: "Below Min. Spending Amount",
        belowMinSpending2: "The minimum spending threshold has not been reached.",
        belowMinSpending3: "Minimum amount:",
        edit: "EDIT",
        frequentBought: "You may like it",
        back: "Back",
        or: "Or",
        loginAsGuest: "Login As Guest",
        MemberLogin: "Member Login",
        // login: "Login / Register",
        login: "Login",
        agree: "Agree",
        reject: "Reject",
        forgetPwd: "Forget Password",
        logout: "Logout",
        memberInfo: "Member Info",
        cashback: "Cashback",
        loyalty: "Loyalty Points",
        rewardDisclaimer: "Kindly place your food first and redeem your voucher/pts upon payment",
        rewardRedeemDisclaimer: "Please redeem your points/ vouchers at the checkout page",
        continue: "Continue",
        generalSubmit: "Submit",
        member: "Member",
        close: "Close",
        register: "Register",
        male: "Male",
        female: "Female",
        agreePdpa: "Agree Terms & Condition",
        email: "Email",
        gender: "Gender",
        topUpAmount: "Top Up Amount",
        discount: "Discount",
        guest: "No of Guest",
        reserveTime: "Reserved Date",
        confirmPayment: "Confirm Payment",
        redeemVoucher: "Redeem Voucher / Loyalty Points",
        somethingWentWrong: "Something went wrong",
        orderFail: "POS failed to Receive",
        orderFailDesc: "Server received your order, however POS didnt response. Please contact waiter (Please don't order again)",
        dob: "DOB",
        promoCode: "Voucher No",
        apply: "Apply",
        pendingOrder: "Pending Order",
        submitAndPay: "Submit and Pay",
        optional: "Optional",
        postal: "Postal",
        unitNo: "Unit No",
        roomNo: "Room No",
        takeAway: "Takeaway",
        reviewOrderStatus: "Check Order Status",
        currentOrderStatus: "Current Kitchen Status",
        preparing: "Preparing",
        readyToCollect: "Ready to Collect",
        pickUpPoint: "Pick Up Point",
        insufficientCredit: "Insufficient Credit",
        redeem: "Redeem"
    },
    {
        minimumConsumptionTip: "需满足最低消费",
        welcome: "欢迎来到",
        table: "桌子",
        order: "下单",
        checkIn: "登入时间",
        noOfPax: "请输入人数",
        enter: "进入",
        paxWarning: "请输入人数",
        orderWarning: "请选择菜单",
        checkout: "下单",
        addToCart: "添加到菜单",
        qty: "数量",
        remark: "备注",
        bgTxt: "请选择类别",
        setMeal: "套餐",
        required: "必选",
        pleasePick: "请选择",
        pleaseSelectItem: "请选择物品",
        subtotal: "小计",
        tax: "税收",
        serviceCharge: "服务费",
        submit: "提交订单",
        serviceChargeReminder: "所有价格均加收消费税",
        total: "总额",
        cart: "Cart",
        reviewOrder: "已下订单",
        cartTitle: "CURRENT ORDER",
        reviewTitle: "查看已下订单",
        selection: "选择",
        paymentFail: "付款失败",
        paymentFailDesc: "交易失败.",
        tryAgain: "请再尝试.",
        returnCK: "回到下单页面",
        success: "下单成功",
        yourOrderNo: "您的单号",
        orderConfirm: "我们已收到您的订单",
        contactShortly: "食物即将准备",
        returnMain: "回到菜单",
        cancel: "取消",
        cancelDesc: "点击以下按钮回到订单页面",
        name: "姓名",
        phone: "联络号码",
        ok: "Ok",
        confirmCK: "确定提交?",
        bookingTime: "接送时间",
        deliveryTime: "接送时间",
        today: "今天",
        tomorrow: "明天",
        selectDateWarning: "请填写电话和时间",
        selectDateWarning1: "请填写电话、时间和地址",
        selectRoomWarning: "需要填上房号, 名字 和 电话号码",
        pleaseSelect: "请选择取餐方式",
        pleasePayment: "请选择支付方式",
        pleaseSelectOne: "请选择",
        delivery: "配送",
        takeIt: "自取",
        deliveryFee: "配送费",
        address: "地址",
        remark: "备注",
        totalAmount: "总金额",
        additional: "额外金额",
        belowMinSpending: "低于最低消费",
        belowMinSpending2: "需要超过一定金额才能线上支付",
        belowMinSpending3: "最低金额:",
        edit: "更改",
        frequentBought: "猜你喜欢",
        back: "返回",
        or: "或者",
        loginAsGuest: "以访客身份登录",
        MemberLogin: "会员登陆",
        // login: "登入 / 注册",
        login: "登入",
        agree: "同意",
        reject: "反对",
        forgetPwd: "忘记密码",
        logout: "退出",
        memberInfo: "会员资料",
        cashback: "返现",
        loyalty: "积分",
        rewardDisclaimer: "请先下单，然后在付款时才兑现优惠卷/积分",
        rewardRedeemDisclaimer: "请在付款前兑现优惠卷/积分",
        continue: "继续",
        generalSubmit: "提交",
        member: "会员",
        close: "关闭",
        register: "注册会员",
        male: "男",
        female: "女",
        agreePdpa: "同意条款和条件",
        email: "邮件",
        gender: "性别",
        topUpAmount: "充值金额",
        discount: "折扣",
        guest: "人数",
        reserveTime: "预定日期",
        confirmPayment: "确认付款",
        redeemVoucher: "兑现优惠卷/分数",
        somethingWentWrong: "Something went wrong",
        orderFail: "POS无法接收",
        orderFailDesc: "服务器已收到订单，但无法传到收银机, 请联系服务员（不必再下单)",
        dob: "生日日期",
        promoCode: "优惠卷",
        apply: "使用",
        pendingOrder: "待处理订单",
        submitAndPay: "下单付款",
        optional: "非必填",
        postal: "邮编",
        unitNo: "单位",
        roomNo: "房号",
        takeAway: "外带",
        reviewOrderStatus: "检查订单",
        currentOrderStatus: "目前厨房状态",
        preparing: "准备当中",
        readyToCollect: "准备领取",
        pickUpPoint: "取餐点",
        insufficientCredit: "余额不足",
        redeem: "兑换"
    }
]